import './App.css';
import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import UploadIcon from '@material-ui/icons/CloudUpload';
import SendIcon from '@material-ui/icons/Send'
import Button from '@material-ui/core/Button';
import logo from './logo.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';



function App() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [images1, setImages1] = useState(null);
  const [images2, setImages2] = useState(null);
  const [images3, setImages3] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelect = (event) => {
    if (event.target.id === 'upload1') {
      setImages1(event.target.files[0]);
    }
    else if (event.target.id === 'upload2') { setImages2(event.target.files[0]) }
    else { setImages3(event.target.files[0]) }
  }
  const handleSubmit = async () => {
    var randomNum = (Math.random()*1000000000).toFixed(0);
    if (name === '' || email === '' || phone === '' || description === '') { setErrorMessage(true) }
    else if(images1 === null){
      setErrorMessage(false);
      setErrorUpload(true);
    } 
    else{
      setErrorMessage(false);
      setLoading(true);
      const formData = new FormData();
      formData.append('avatar1', images1);
      formData.append('avatar2', images2);
      formData.append('avatar3', images3);
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('description', description);
      formData.append('Id', randomNum)
      await axios.post('https://photos.milanlaser.com/photo_submission_may21.php', formData, {
        headers: {
          'content-type': 'multipart/form-data',
          'Cache-Control': 'no-cache' 
        }
      }).then((res)=>{
        axios.post('https://milan-cors-2023-9de078d0fd3b.herokuapp.com/https://us-central1-milanlaser-fcb24.cloudfunctions.net/api/sendCompInfo', {"name": name, "email": email, "mobile": phone, "description": description, "dbId": randomNum})
      })
        .then(function (response) {
          setLoading(false);
          setEmailSuccess(true);

          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  }
  return (
    <Grid container style={{ textAlign: 'center', flexDirection: 'row' }}>
      <Grid item md={12} xs={12} sm={12} style={{ marginBottom: 20 }}><img src={logo} width={150} alt="Milan Laser Logo" />
        <Typography variant="h5" style={{ color: '#01718f' }}>Photo Submission</Typography>
      </Grid>
      {emailSuccess ? <Grid item md={12} sm={12} xs={12}>
        <Typography variant="h5" style={{ color: 'green' }}>Your submission was successful.</Typography>
        <Typography variant="h6" style={{ color: 'black' }}>If you have not already reported this concern to the clinic, please do so now.</Typography>
        <Typography variant="h6" style={{ color: 'black' }}><a href="https://milanlaser.com/locations">Clinic Phone Numbers.</a></Typography>
      </Grid> : loading ? <Grid item md={12} sm={12} xs={12} style={{ marginBottom: 30, marginTop: 30 }}>
        <CircularProgress/>
      </Grid> :
          <><Grid item md={3} sm={3} xs={2}><p>&nbsp;</p></Grid>
            <Grid item md={6} sm={6} xs={8}>
              <TextField label="Full Name" required value={name} onChange={e => setName(e.target.value)} style={{ width: '100%' }} />
              <p></p>
              <TextField label="Email" required value={email} onChange={e => setEmail(e.target.value)} type="email" style={{ width: '100%' }} />
              <p></p>
              <TextField label="Phone" required value={phone} onChange={e => setPhone(e.target.value)} type='text' style={{ width: '100%' }} />
              <p></p>
              <TextField label="Description of Photo(s)" required value={description} onChange={e => setDescription(e.target.value)} multiline style={{ width: '100%' }} />
            </Grid>
            <Grid item md={3} sm={3} xs={2}><p>&nbsp;</p></Grid>
            <Grid item md={12} xs={12} sm={12} style={{ marginTop: 20 }}>
              <Typography variant="h6">Upload Images (Optional)</Typography>
              <p></p>
              {images1 ? <Typography>{images1.name} - {Math.round(images1.size/1024)}kb - Attached</Typography> : <><Button variant="outlined" onClick={() => { document.getElementById('upload1').click() }} color="primary" startIcon={<UploadIcon />}>Upload</Button>
                <input type='file' id='upload1' onChange={handleSelect} hidden /></>}
              <p></p>
              {images2 ? <Typography>{images2.name} - Attached</Typography> : <><Button variant="outlined" disabled={images1 === null} onClick={() => { document.getElementById('upload2').click() }} color="primary" startIcon={<UploadIcon />}>Upload</Button>
                <input type='file' id='upload2' onChange={handleSelect} hidden /></>}
              <p></p>
              {images3 ? <Typography>{images3.name} - Attached</Typography> : <><Button disabled={images1 === null || images2 === null} variant="outlined" onClick={() => { document.getElementById('upload3').click() }} color="primary" startIcon={<UploadIcon />}>Upload</Button>
                <input type='file' id='upload3' onChange={handleSelect} hidden /></>}
              <p></p>
              {(errorUpload && !images1)  && <><Typography variant="body1" style={{ color: 'red' }}>Please upload at least 1 photo</Typography>
                <p></p></>}  
              
              {errorMessage ? <><Typography variant="body1" style={{ color: 'red' }}>Please fill out all the required fields.</Typography>
                <p></p></> : null}

               
              <Button variant="contained"  onClick={handleSubmit} style={{ width: 200, backgroundColor: '#01718f', color: 'white', marginTop: 20, marginBottom: 20 }} startIcon={<SendIcon />}>Submit</Button>
              <Typography variant="body2" style={{marginLeft:15, marginRight:15, marginBottom:20, color:'#888888'}}>Privacy Notice: Milan Laser is the sole owner and operator of this site. Any information submitted on this secure page is encrypted for your privacy and is for internal use ONLY. It is only viewed by a team of licensed medical providers who strictly abide by and receive annual training for HIPAA compliance.</Typography>
            </Grid>
          </>}
    </Grid>
  );
}

export default App;
